// @flow
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Send from '@material-ui/icons/Send';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import styled from 'styled-components';
import * as React from 'react';
import Section from '../components/Section';
import ArrowLink from '../components/ArrowLink';
import Button from '../components/button';
import Footer from '../components/Footer';
import Header from '../components/header';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { LARGE_BREAKPOINT, query, XL_BREAKPOINT } from '../style';
import { REVIEW_LINK } from '../utils/constants';

import type { ImageSharp, Provider } from '../types';

function encode(data: { [string]: string }) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

type Props = {};
type Value<T> = { value: T, error: ?string, pristine: boolean };
type FormType = {
  fullName: Value<string>,
  phone: Value<string>,
  email: Value<string>,
  feedback: Value<string>,
  stars: Value<number>,
};

type State = {
  stars: number,
  form: FormType,
  status: 'FILLING' | 'ERROR' | 'PENDING' | 'FAILED' | 'SUCCESS' | 'STAR',
};

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function initialize<T>(v: T): Value<T> {
  return {
    value: v,
    error: null,
    pristine: true,
  };
}
const initialString = initialize('');

const initialForm = {
  fullName: initialString,
  phone: initialString,
  email: initialString,
  feedback: initialString,
  stars: initialize(0),
};

const RatingStar = ({
  filled = false,
  border = false,
  onHover,
  // onMouseOut,
  onClick,
  num,
}: {
  filled: boolean,
  border: boolean,
  num: number,
  onClick: any,
  onHover: any,
  // onMouseOut: any,
}) =>
  filled ? (
    <Star
      style={{
        width: '18%',
        height: 'initial',
        cursor: 'pointer',
        color: '#ffed00',
      }}
      onMouseOver={() => onHover(num)}
      onClick={() => onClick(num)}
      //onMouseOut={onMouseOut}
    />
  ) : (
    <StarBorder
      style={{
        width: '18%',
        height: 'initial',
        cursor: 'pointer',
        color: border ? '#ffed00' : 'inherit',
      }}
      onMouseOver={() => onHover(num)}
      onClick={() => onClick(num)}
      //onMouseOut={onMouseOut}
    />
  );

export default class RequestAppointment extends React.Component<Props, State> {
  state: State = {
    form: initialForm,
    status: 'STAR',
    stars: 0,
  };

  handleChange = (e: SyntheticInputEvent<>) => {
    const name: string = e.target.id;
    const value = e.target.value;
    this.setState(({ form, ...state }) => {
      return {
        ...state,
        form: {
          ...form,
          [name]: {
            pristine: true,
            value,
            error: this.validate(name, value),
          },
        },
      };
    });
  };

  handleBlur = (e: SyntheticInputEvent<>) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState(({ form, ...state }) => {
      const old: Value<string | number> = form[name];
      return {
        ...state,
        form: {
          ...form,
          [name]: {
            ...old,
            pristine: false,
            error: this.validate(name, value),
          },
        },
      };
    });
  };

  handleHoverStar = (n: number) =>
    this.setState(state => ({ ...state, stars: n }));

  handleStarSubmit = () =>
    this.state.form.stars.value > 3
      ? window.open(REVIEW_LINK, '_blank')
      : this.setState(state => ({ ...state, status: 'FILLING' }));

  handleStarClick = (n: number) =>
    this.setState(state => ({
      ...state,
      form: { ...state.form, stars: { ...state.form.stars, value: n } },
    }));

  handleMouseOut = () => this.setState(state => ({ ...state, stars: 0 }));

  validate = <T>(name: string, value: T) => {
    if (typeof value !== 'string') return null;
    switch (name) {
      case 'email':
        if (!EMAIL_REGEX.test(value))
          return 'This appears to be an invalid email address';
        return null;
      case 'phone':
        if (!value || value.replace(/\D/g, '').length !== 10)
          return 'Phone number must be 10 digits long';
        return null;
      case 'fullName':
        if (!value || value.length < 3) return 'Please enter your full name';
        return null;
      case 'reason':
        if (!value || value.length < 5)
          return 'Please let us know how we can improve';
        return null;
      case 'provider':
        return null;
      default:
        return null;
    }
  };

  handleSubmit = () => {
    // check that they are all valid
    let err = false;
    const { form } = this.state;
    const nextForm = initialForm;
    Object.keys(form).forEach(key => {
      const name = key;
      const value = form[key].value;
      const error = this.validate(key, value);
      err = err || error;

      if (name === 'stars' && typeof value === 'number') {
        nextForm.stars = {
          value,
          error,
          pristine: false,
        };
      } else if (name !== 'stars' && typeof value === 'string') {
        nextForm[name] = {
          value,
          error,
          pristine: false,
        };
      } else {
        console.warn('Form entry has incorrect type in handleSubmit');
      }

      this.setState({ form: nextForm });
    });

    if (err) return this.setState({ status: 'ERROR' });

    // actually submit this thing.
    this.setState({ status: 'PENDING' });
    const formData = this.extractFormdata();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'feedback', ...formData }),
    })
      .then(res => {
        if (res.ok) {
          this.setState({ status: 'SUCCESS' });
        } else {
          this.setState({
            status: 'FAILED',
          });
        }
      })
      .catch(error => {
        alert(error);
        this.setState({ status: 'FAILED' });
      });
  };

  extractFormdata = () => {
    return Object.keys(this.state.form).reduce((acc, k) => {
      acc[k] = this.state.form[k].value;
      return acc;
    }, {});
  };

  render() {
    const { form, status } = this.state;
    const err = Object.keys(form).reduce((prev, key) => {
      return (form[key].error && !form[key].pristine) || prev;
    }, false);
    const color = '#444F5A';
    /**
     * If you are in 'star' mode, render the stars content, otherwise show the filling or success
     */
    return (
      <Page
        style={{ background: 'linear-gradient(90deg,#777bf8,#68d4f8 200%)' }}
        title="Leave us feedback"
      >
        <Header
          inverse
          thin
          centered
          // short
          cta={null}
          simpleBackground="#ECF5FD"
          title=""
          subtitle=""
        />
        <HeaderSection
          style={{ zIndex: 0 }}
          backgroundStyle={{
            top: '-200%',
            bottom: -110,
            height: 'initial',
          }}
          color="#edf5fd"
        >
          <Title
            style={{
              color,
              textAlign: 'center',
              alignSelf: 'stretch',
            }}
          >
            Leave us Feedback
          </Title>
          <Subtitle
            style={{
              color,
              textAlign: 'center',
              alignSelf: 'stretch',
            }}
          >
            We want to provide the best care, and the most comfortable,
            friendly, positive experience possible. This means a constant focus
            on improvement, and we love getting feedback to know where we can
            improve.
          </Subtitle>
        </HeaderSection>
        <StyledSection color="rgba(0,0,0,0)">
          <form
            name="feedback"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="feedback" />
            <Card raised style={{ position: 'relative' }}>
              <Stars
                onMouseOut={this.handleMouseOut}
                style={{
                  opacity: status === 'STAR' ? 1 : 0,
                  display: status === 'STAR' ? 'visible' : 'none',
                }}
              >
                <StarTitle>
                  First, how would you rate your overall experience?
                </StarTitle>
                <StarContent>
                  <RatingStar
                    num={1}
                    border={this.state.stars >= 1}
                    filled={form.stars.value >= 1}
                    onHover={this.handleHoverStar}
                    onClick={this.handleStarClick}
                    //onMouseOut={this.handleMouseOut}
                  />
                  <RatingStar
                    num={2}
                    border={this.state.stars >= 2}
                    filled={form.stars.value >= 2}
                    onHover={this.handleHoverStar}
                    onClick={this.handleStarClick}
                    //onMouseOut={this.handleMouseOut}
                  />
                  <RatingStar
                    num={3}
                    border={this.state.stars >= 3}
                    filled={form.stars.value >= 3}
                    onHover={this.handleHoverStar}
                    onClick={this.handleStarClick}
                    //onMouseOut={this.handleMouseOut}
                  />
                  <RatingStar
                    num={4}
                    border={this.state.stars >= 4}
                    filled={form.stars.value >= 4}
                    onHover={this.handleHoverStar}
                    onClick={this.handleStarClick}
                    //onMouseOut={this.handleMouseOut}
                  />
                  <RatingStar
                    num={5}
                    border={this.state.stars >= 5}
                    filled={form.stars.value >= 5}
                    onHover={this.handleHoverStar}
                    onClick={this.handleStarClick}
                    //onMouseOut={this.handleMouseOut}
                  />
                </StarContent>
              </Stars>
              <SuccessMessage success={status == 'SUCCESS'}>
                <SuccessHeader>We appreciate your thoughts!</SuccessHeader>
                <SuccessBody>
                  We will be working on this, and will get in touch if you asked
                  us to follow up with you.
                </SuccessBody>
                <ArrowLink to="/">Go back to home</ArrowLink>
              </SuccessMessage>
              <CardContent
                style={{
                  display:
                    status === 'SUCCESS' || status === 'STAR'
                      ? 'none'
                      : 'block',
                }}
              >
                <TextField
                  name="fullName"
                  id="fullName"
                  label="Full Name"
                  placeholder="Jane Doe"
                  value={form.fullName.value}
                  margin="normal"
                  fullWidth
                  required
                  onChange={this.handleChange}
                  helperText={!form.fullName.pristine && form.fullName.error}
                  error={!form.fullName.pristine && !!form.fullName.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="jane@example.com"
                  value={form.email.value}
                  margin="normal"
                  fullWidth
                  required
                  onChange={this.handleChange}
                  helperText={!form.email.pristine && form.email.error}
                  error={!form.email.pristine && !!form.email.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />
                <TextField
                  name="phone"
                  id="phone"
                  label="Phone Number"
                  placeholder="(208) 222-2222"
                  value={form.phone.value}
                  margin="normal"
                  fullWidth
                  type="tel"
                  required
                  onChange={this.handleChange}
                  helperText={!form.phone.pristine && form.phone.error}
                  error={!form.phone.pristine && !!form.phone.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="feedback"
                  name="feedback"
                  label="Your Feedback"
                  value={form.feedback.value}
                  margin="normal"
                  fullWidth
                  rows="4"
                  required
                  multiline
                  onChange={this.handleChange}
                  helperText={!form.feedback.pristine && form.feedback.error}
                  error={!form.feedback.pristine && !!form.feedback.error}
                  onBlur={this.handleBlur}
                />
              </CardContent>
              <CardActions
                style={{
                  padding: 16,
                  justifyContent: 'flex-end',
                  height: 'initial',
                  visibility: status == 'SUCCESS' ? 'hidden' : 'visible',
                }}
              >
                {status == 'STAR' ? (
                  <Button
                    color="secondary"
                    disabled={form.stars.value == 0}
                    onClick={this.handleStarSubmit}
                    arrow
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    disabled={err || status == 'PENDING' || status == 'SUCCESS'}
                    onClick={this.handleSubmit}
                  >
                    Send <Send style={{ marginLeft: 8, rotate: '45deg' }} />
                  </Button>
                )}
              </CardActions>
            </Card>
          </form>
        </StyledSection>
        <Footer />
      </Page>
    );
  }
}

const StarContent = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const StarTitle = styled.h1`
  && {
    text-align: center;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    ${query(LARGE_BREAKPOINT)`
      font-size: 40px;
    `};
  }
`;

const Stars = styled.div`
  && {
    padding: 16px;
    transition: all 0.5s ease-in-out;
  }
`;

const SuccessMessage = styled.div`
  && {
    opacity: ${({ success }) => (success ? 1 : 0)};
    padding: 16px;
    transition: all 0.5s ease-in-out;
    display: ${({ success }) => (success ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const SuccessHeader = styled.h1`
  && {
    text-align: center;
    ${query(LARGE_BREAKPOINT)`
      font-size: 40px;
    `};
  }
`;

const SuccessBody = styled.p`
  && {
    color: #79a6eb;
    font-size: 30px;
    text-align: center;
    line-height: 1.3;
  }
`;

const HeaderSection = styled(Section)`
  && {
    padding: 20px 20px 0px 20px;
    ${query(LARGE_BREAKPOINT)`
      padding: 60px 20px 0px 20px;
    `};
    max-width: 870px;
  }
`;

const Title = styled.h1`
  && {
    color: white;
    ${query(LARGE_BREAKPOINT)`
    font-size: 40px;
    margin-bottom: 50px;
  `};
  }
`;

const Subtitle = styled.p`
  && {
    color: rgba(255, 255, 255, 0.85);
    ${query(LARGE_BREAKPOINT)`
    font-size: 20px;
    margin-bottom: 70px;
  `};
    ${query(XL_BREAKPOINT)`
    font-size: 22px;
  `};
  }
`;

const StyledSection = styled(Section)`
  && {
    padding: 30px 20px 100px 20px;
  }
`;

/**
 * FIELDS:
 *    name
 *    email
 *    phone
 *    reason for appointment
 *    requested provider (drop down)
 *
 * VALIDATIONS
 *    not empty
 *    valid email
 *    valid phone number
 */
